import React, { PropsWithChildren, useState } from 'react'
import { graphql, PageProps } from 'gatsby';
import { GFooter } from '../../interface/graphql/GFooter';
import { GSeo } from '../../interface/graphql/GSeo';
import { GHeader } from '../../interface/graphql/GHeader';
import { getImageUrl } from '../../functions/getImageUrl';
import { App } from '../../components/App';

import { GImage } from '../../interface/graphql/GImage';
import { mapKeyValues } from '../../functions/mapKeyValues';
import { GBlogPost } from '../../interface/graphql/GBlogPost';
import { NewBlogPostPageContent } from './components/NewBlogPostPageContent/NewBlogPostPageContent';
import { GLink } from '../../interface/graphql/GLink';
import { NewMainBrandLayout } from '../../layouts/NewMainBrandLayout';
import {
  useTheme
} from '@material-ui/core'
import { ContentHeaderLayout } from '../../layouts/ContentHeaderLayout';
import { NewsLetter } from '../../components/Newsletter/Newsletter';

export interface IPropNewBlogPostPageTemplate {
  allContentfulKeyValue: any;
  contentfulBlogPostPage: {
    title: string;
    locale: string;
    slug: string;
    shareText: string;
    backLink: GLink;
    blogPost: GBlogPost;
    header: GHeader;
    footer: GFooter;
    seo: GSeo;
  };
  contentfulHomePage: {
    ctaLink: GLink;
  }
}

const PageContent = (props: PropsWithChildren<{ logo: GImage; header: GHeader; blogPost: GBlogPost; shareText: string; backLink: GLink; ctaLink: GLink; html?: string; }>) => {
    const {
        logo, header, blogPost, shareText, backLink, ctaLink,
    } = props;
    const theme = useTheme();

    return (
        <NewMainBrandLayout
            logoUrl={getImageUrl(logo)}
            header={{ mainSlug: header.mainSlug }}
            headerBgShadeColor={theme.palette.primary.main}
            childrenUnderBackground={(
                <>
                  <NewBlogPostPageContent blogPost={blogPost} shareText={shareText} backLink={backLink} ctaLink={ctaLink} />
                  {/* <NewsLetter /> */}
                </>
            )}
        >
           <ContentHeaderLayout
              image={blogPost.listImage}
              title={blogPost.title}
              shareText={shareText}
              date={blogPost.date}
              backLink={backLink}
           />
        </NewMainBrandLayout>
    )
}
const NewBlogPostPageTemplate = (props: PageProps<IPropNewBlogPostPageTemplate>) => {
    const { data: { contentfulBlogPostPage, contentfulHomePage, allContentfulKeyValue }, pageContext } = props;
    const {
        seo, header, footer, blogPost, shareText, backLink
    } = contentfulBlogPostPage;
    const {
      ctaLink
    } = contentfulHomePage
    const keyValues = mapKeyValues(allContentfulKeyValue.nodes);
    return (
        <App
            pageContext={pageContext as any}
            seoContext={seo}
            header={header}
            footerContext={footer}
            keyValuesContext={keyValues}
        >
            <PageContent
                logo={header.logo}
                header={header}
                blogPost={blogPost}
                shareText={shareText}
                backLink={backLink}
                ctaLink={ctaLink}
            />
        </App>
    )
}

export default NewBlogPostPageTemplate

export const queryPage = graphql`
query ContentfulNewBlogPostPageBySlugAndLocale($slug: String, $locale: String, $featureFlagsQuery: StringQueryOperatorInput) {
  allContentfulKeyValue(filter: {node_locale: {eq:$locale}}) {
    nodes {
      key {
        key
      }
      locale: node_locale
      value {
        value
      }
    }
  },
  contentfulHomePage(node_locale: { eq: $locale }, featureFlags: $featureFlagsQuery) {
    ctaLink {
      ...LinkFragment
    }
  }
  contentfulBlogPostPage(slug: {eq: $slug}, node_locale: {eq: $locale}) {
    locale: node_locale
    slug
    title
    shareText
    backLink {
      ...LinkFragment
    }
    blogPost {
      title
      html {
        longtext {
          longtext
        }
      }
      date(formatString: "DD. MMMM YYYY", locale: $locale)
      category
      teaserText {
        children {
          id
          internal {
            content
          }
        }
      }
      mainImage {
        ...ImageFragment
      }
      listImage {
        ...ImageFragment
      }
      body {
        children {
          id
          internal {
            content
          }
        }
      }
      footnote {
        children {
          id
          internal {
            content
          }
        }
      }
    }
    footer {
      ...FooterFragment
    }
    seo {
      ...SeoFragment
    }
    header {
      ...HeaderFragment
    }
  }
}
`
