// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-contentful-pages-calculator-page-calculator-page-template-tsx": () => import("./../../../src/contentfulPages/CalculatorPage/CalculatorPageTemplate.tsx" /* webpackChunkName: "component---src-contentful-pages-calculator-page-calculator-page-template-tsx" */),
  "component---src-contentful-pages-climate-offset-project-page-climate-offset-project-page-template-tsx": () => import("./../../../src/contentfulPages/ClimateOffsetProjectPage/ClimateOffsetProjectPageTemplate.tsx" /* webpackChunkName: "component---src-contentful-pages-climate-offset-project-page-climate-offset-project-page-template-tsx" */),
  "component---src-contentful-pages-new-blog-post-list-page-new-blog-post-list-page-template-tsx": () => import("./../../../src/contentfulPages/NewBlogPostListPage/NewBlogPostListPageTemplate.tsx" /* webpackChunkName: "component---src-contentful-pages-new-blog-post-list-page-new-blog-post-list-page-template-tsx" */),
  "component---src-contentful-pages-new-blog-post-page-new-blog-post-page-template-tsx": () => import("./../../../src/contentfulPages/NewBlogPostPage/NewBlogPostPageTemplate.tsx" /* webpackChunkName: "component---src-contentful-pages-new-blog-post-page-new-blog-post-page-template-tsx" */),
  "component---src-contentful-pages-new-faq-page-faq-page-template-tsx": () => import("./../../../src/contentfulPages/NewFaqPage/FaqPageTemplate.tsx" /* webpackChunkName: "component---src-contentful-pages-new-faq-page-faq-page-template-tsx" */),
  "component---src-contentful-pages-new-home-page-new-home-page-template-tsx": () => import("./../../../src/contentfulPages/NewHomePage/NewHomePageTemplate.tsx" /* webpackChunkName: "component---src-contentful-pages-new-home-page-new-home-page-template-tsx" */),
  "component---src-contentful-pages-new-landing-page-landing-page-template-tsx": () => import("./../../../src/contentfulPages/NewLandingPage/LandingPageTemplate.tsx" /* webpackChunkName: "component---src-contentful-pages-new-landing-page-landing-page-template-tsx" */),
  "component---src-contentful-pages-new-who-we-are-page-new-who-we-are-page-template-tsx": () => import("./../../../src/contentfulPages/NewWhoWeArePage/NewWhoWeArePageTemplate.tsx" /* webpackChunkName: "component---src-contentful-pages-new-who-we-are-page-new-who-we-are-page-template-tsx" */),
  "component---src-contentful-pages-not-found-page-new-not-found-page-template-tsx": () => import("./../../../src/contentfulPages/NotFoundPage/NewNotFoundPageTemplate.tsx" /* webpackChunkName: "component---src-contentful-pages-not-found-page-new-not-found-page-template-tsx" */),
  "component---src-contentful-pages-redirect-redirect-template-tsx": () => import("./../../../src/contentfulPages/Redirect/RedirectTemplate.tsx" /* webpackChunkName: "component---src-contentful-pages-redirect-redirect-template-tsx" */),
  "component---src-contentful-pages-static-page-static-page-template-tsx": () => import("./../../../src/contentfulPages/StaticPage/StaticPageTemplate.tsx" /* webpackChunkName: "component---src-contentful-pages-static-page-static-page-template-tsx" */),
  "component---src-contentful-pages-success-page-success-page-template-tsx": () => import("./../../../src/contentfulPages/SuccessPage/SuccessPageTemplate.tsx" /* webpackChunkName: "component---src-contentful-pages-success-page-success-page-template-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-compensation-success-tsx": () => import("./../../../src/pages/compensation/success.tsx" /* webpackChunkName: "component---src-pages-compensation-success-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

