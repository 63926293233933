import { GImage } from "../interface/graphql/GImage";
import React, { useCallback, useContext, useLayoutEffect, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { useViewport, BREAKPOINTS, Margin } from "@cpyou/shared/dist";
import { LayoutContext } from "../context/layoutContext";
import { IPageContext } from "src/interface/PageContext";
import { PageContext } from "./MainLayout";
import { GLink } from "../interface/graphql/GLink";
import { getClasses } from "./ContentHeaderLayout.jss";
import { NewControlBar } from "../contentfulPages/NewBlogPostPage/components/NewControlBar";


export function ContentHeaderLayout({
    title,
    image,
    shareText,
    date,
    backLink
}: {
    title: string;
    image: GImage;
    shareText?: string;
    date: Date,
    backLink?: GLink
}) {

    const { containerRef } = useContext(LayoutContext);
    const sizeModel = useViewport(BREAKPOINTS, containerRef);
    const { language, coBrand } = useContext(PageContext) as IPageContext;
    const classes = getClasses();

    const [layoutContainerSpace, setLayoutContainerSpace] = useState({
        left: 0,
        center: 0,
        right: 0,
    });
    const [imageWidth, setImageWidth] = useState(367)
    const calcLayoutSpace = useCallback(() => {
        if (sizeModel.isMobile)
            setLayoutContainerSpace({ left: 0, center: 12, right: 0 });
        if (sizeModel.isTablet || sizeModel.isDesktop || sizeModel.isDesktopXL || sizeModel.isDesktopXXL)
            setLayoutContainerSpace({ left: 1, center: 10, right: 1 });
    }, [sizeModel.viewportWidth]);

    const calcImageWidth = useCallback(()=> {
        if (sizeModel.isMobile || sizeModel.isTablet)
            setImageWidth(sizeModel.viewportWidth)
        if (sizeModel.isDesktop)
            setImageWidth(367)
        if (sizeModel.isDesktopXL)
            setImageWidth(380)
        if (sizeModel.isDesktopXXL)
            setImageWidth(594)

    }, [sizeModel.viewportWidth])
    useLayoutEffect(() => {
        calcLayoutSpace();
        calcImageWidth();
    }, [sizeModel.viewportWidth]);


    const flexDirection =
        sizeModel.isMobile || sizeModel.isTablet ? "column" : "row-reverse";

    const leftWidth =
        layoutContainerSpace.left *
        (sizeModel.columnWidth + sizeModel.gutterWidth);
    const centerWidth =
        layoutContainerSpace.center *
            (sizeModel.columnWidth + sizeModel.gutterWidth) -
        sizeModel.gutterWidth;
    const rightWidth =
        layoutContainerSpace.right *
        (sizeModel.columnWidth + sizeModel.gutterWidth);

    const contentHeaderTopMargin =  sizeModel.isMobile || sizeModel.isTablet ? "20px" : "80px"
    const marginLeft= `-${sizeModel.marginWidth}px`
    const marginRight=
        sizeModel.isMobile ||  sizeModel.isTablet
            ? `-${sizeModel.marginWidth}px`
            : sizeModel.isDesktop
            ? `${sizeModel.gutterWidth}px`
            : `${sizeModel.columnWidth}px`

    const headingContainerMarginLeft =
      sizeModel.isTablet
      ?  `${sizeModel.gutterWidth + sizeModel.columnWidth}px`
      : undefined

    return (
        <Box display="flex">
            <Margin viewportEl={containerRef} />
            <Box width={leftWidth} />
            <Box display="flex" flexDirection={flexDirection} marginTop={contentHeaderTopMargin}>
                <Box
                    display="flex"
                    flexDirection="column"
                    marginLeft={headingContainerMarginLeft}
                    width={
                        sizeModel.isMobile ||  sizeModel.isTablet
                        ? centerWidth
                        : undefined }
                >
                    <NewControlBar
                        language={language}
                        coBrand={coBrand}
                        shareText={shareText}
                        shareTitle={title}
                        backLink={backLink}
                        containerClass={classes.controllBarContainerClass}
                        shareBarIconClass={classes.sharebarIcon}
                    />
                    <Typography variant="h3" className={classes.contentHeaderTitle}>{title}</Typography>
                    <Typography variant="body1" className={classes.contentHeaderDate}>{date}</Typography>
                </Box>
                <Box className={classes.contentHeaderImage} marginLeft={marginLeft} marginRight={marginRight}>
                    <img width={imageWidth} src={image.file.url}></img>
                </Box>

            </Box>
            <Box width={rightWidth} />
            <Margin viewportEl={containerRef} />
        </Box>
    );
}
