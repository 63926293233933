import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

export const getClasses = makeStyles(({ palette, breakpoints }: Theme) => ({
    blogPostListItemDate: {
        color: palette.text.secondary,
        fontSize: "14px",
    },
    blogPostListItemTitle: {
        paddingTop: "16px",
        paddingBottom: "16px",
        [breakpoints.down("md")]: {
            fontSize: 24,
        },
        [breakpoints.up("lg")]: {
            fontSize: 40,
        },
    },
    blogPostListItemImageContainer: {
        paddingLeft: 0,
        paddingRight: 0,
    },

    blogPostListItemCategory: {
        color: palette.text.secondary,
        paddingTop: "16px",
    },
    blogPostListItemReadMoreLink: {
        textDecorationLine: "none",
        whiteSpace: "nowrap",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        paddingTop: "16px",
    },
    blogPostListItemTextContainer: {
        [breakpoints.down("sm")]: {
            paddingTop: "40px",
            fontSize: 16,
        },
        [breakpoints.up("md")]: {
            paddingTop: "16px",
            paddingLeft: "16px",
        },
    },
    blogPostListItemTeaserText: {
        lineHeight: 1.5,
    },
}));
