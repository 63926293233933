import React, {
    PropsWithChildren,
    useContext,
    useEffect,
    useLayoutEffect,
    useState,
} from "react";
import { graphql, PageProps } from "gatsby";
import { useTheme, Box, Typography } from "@material-ui/core";
import { GFooter } from "../../interface/graphql/GFooter";
import { GSeo } from "../../interface/graphql/GSeo";
import { GHeader } from "../../interface/graphql/GHeader";
import { getImageUrl } from "../../functions/getImageUrl";
import { App } from "../../components/App";
import { GImage } from "../../interface/graphql/GImage";
import { MainLayout } from "../../layouts/MainLayout";
import { mapKeyValues } from "../../functions/mapKeyValues";
import { GBlogPost } from "../../interface/graphql/GBlogPost";
import { NewBlogPostListContent } from "./NewBlogPostListContent";
import {
    useViewport,
    BREAKPOINTS,
    useForceUpdate,
    useOnResize,
} from "@cpyou/shared/dist";
import { NewsLetter } from "../../components/Newsletter/Newsletter";
import { NewMainBrandLayout } from "../../layouts/NewMainBrandLayout";
import { KeyValuesContext } from "../../components/KeyValuesContext";

export interface IPropNewBlogPostListPageTemplate {
    allContentfulKeyValue: any;
    contentfulBlogPostListPage: {
        title: string;
        locale: string;
        slug: string;
        blogPosts: Array<GBlogPost>;
        background: GImage;
        backgroundMobile: GImage;
        header: GHeader;
        footer: GFooter;
        seo: GSeo;
    };
}

const PageContent = (
    props: PropsWithChildren<{
        background: GImage;
        backgroundMobile: GImage;
        logo: GImage;
        title: string;
        header: GHeader;
        blogPosts: Array<GBlogPost>;
    }>
) => {
    const { logo, title, header, blogPosts } = props;

    const [displayBlogPosts, setDisplayBlogPosts] = useState([]);

    useEffect(() => {
        // sort blogs after date
        blogPosts.sort(
            (a, b) => new Date(b.rawDate).getTime() - new Date(a.rawDate).getTime()
        );

        // filter out posts that are published in the future
        const alreadyPublishedPosts = blogPosts.filter(
            (blogPost) =>
                new Date().getTime() >= new Date(blogPost.rawDate).getTime()
        );

        setDisplayBlogPosts(alreadyPublishedPosts);
    }, [blogPosts]);

    const [containerRef] = useState<Element>();
    const sizeModel = useViewport(BREAKPOINTS, containerRef);

    const titleMarginLeft = sizeModel.isMobile
        ? 0
        : sizeModel.columnWidth + sizeModel.gutterWidth;
    const subtitleMarginRight =
        6 * (sizeModel.columnWidth + sizeModel.gutterWidth) -
        sizeModel.gutterWidth;
    const theme = useTheme();
    const forceUpdate = useForceUpdate();

    useLayoutEffect(() => {
        // eslint-disable-next-line
        return useOnResize(
            () => {
                forceUpdate();
            },
            BREAKPOINTS,
            containerRef
        );
    });

    const keyValues = useContext(KeyValuesContext);

    return (
        <NewMainBrandLayout
            logoUrl={getImageUrl(logo)}
            header={{ mainSlug: header.mainSlug }}
            headerBgShadeColor={theme.palette.primary.main}
            childrenUnderBackground={
                <>
                    {displayBlogPosts.map((blogPost, index) => (
                        <NewBlogPostListContent
                            key={`post-${blogPost.id}`}
                            blogPost={blogPost}
                            isLastBlogPost={index === displayBlogPosts.length - 1}
                            isFirstBlogPost={index === 0}
                        />
                    ))}
                    {/* <NewsLetter /> */}
                </>
            }
        >
            <Box marginLeft={`${titleMarginLeft}px`}>
                <Typography variant={"h1"}>{title}</Typography>
                <Typography variant={"h1"}>&mdash;</Typography>
                <Box marginRight={`${subtitleMarginRight}px`}>
                    <Typography variant={"body1"}>
                        {keyValues.blogPostListPageSubtitle}
                    </Typography>
                </Box>
            </Box>
        </NewMainBrandLayout>
    );
};
const NewBlogPostListPageTemplate = (
    props: PageProps<IPropNewBlogPostListPageTemplate>
) => {
    const {
        data: { contentfulBlogPostListPage, allContentfulKeyValue },
        pageContext,
    } = props;
    const {
        seo,
        header,
        footer,
        title,
        background,
        blogPosts,
        backgroundMobile,
    } = contentfulBlogPostListPage;
    const keyValues = mapKeyValues(allContentfulKeyValue.nodes);

    return (
        <App
            pageContext={pageContext as any}
            seoContext={seo}
            header={header}
            footerContext={footer}
            keyValuesContext={keyValues}
        >
            <PageContent
                background={background}
                backgroundMobile={backgroundMobile}
                logo={header.logo}
                title={title}
                header={header}
                blogPosts={blogPosts}
            />
        </App>
    );
};

export default NewBlogPostListPageTemplate;

export const queryPage = graphql`
    query ContentfulNewBlogPostListPageBySlugAndLocale(
        $slug: String
        $locale: String
    ) {
        allContentfulKeyValue(filter: { node_locale: { eq: $locale } }) {
            nodes {
                key {
                    key
                }
                locale: node_locale
                value {
                    value
                }
            }
        }
        contentfulBlogPostListPage(
            slug: { eq: $slug }
            node_locale: { eq: $locale }
        ) {
            locale: node_locale
            slug
            title
            blogPosts {
                id
                title
                rawDate: date
                date(formatString: "DD. MMMM YYYY", locale: $locale)
                category
                blogpostpage {
                    slug
                }
                teaserText {
                    internal {
                        content
                    }
                }
                mainImage {
                    ...ImageFragment
                }
                listImage {
                    ...ImageFragment
                }
                body {
                    children {
                        id
                        internal {
                            content
                        }
                    }
                }
            }
            background {
                ...ImageFragment
            }
            backgroundMobile {
                ...ImageFragment
            }
            footer {
                ...FooterFragment
            }
            seo {
                ...SeoFragment
            }
            header {
                ...HeaderFragment
            }
        }
    }
`;
