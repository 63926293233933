import { Box } from "@material-ui/core"
import React from "react"
import { ArrowLeftIcon } from "../../../components/icon/ArrowLeftIcon"
import { Sharebar } from "../../../components/Sharebar"
import { pageToLink } from "../../../functions/pageToLink"
import {getClasses } from "./NewControlBar.jss"
import { Link } from "gatsby"
import { ICoBrand } from "@cpyou/shared/dist"
import { GLink } from "../../../interface/graphql/GLink"
import { joinClasses } from "../../../functions/join"

export const NewControlBar = ({
  language, coBrand, shareText, shareTitle, backLink, containerClass, shareBarIconClass } :
  {language: string,
    coBrand: ICoBrand,
    shareText: string,
    shareTitle: string,
    backLink: GLink,
    containerClass?: string,
    shareBarIconClass?: string
  } ) => {
  const classes = getClasses()
  return (
    <Box className={joinClasses([classes.shareBarContainer, containerClass])}>
          <Link to={pageToLink(language, coBrand, backLink.page)} className={classes.backLink}>
              <ArrowLeftIcon color={coBrand ? coBrand.primaryColor : undefined} style={{height: 12, width: 18, marginRight:10}} />
              &nbsp;
              {backLink.title}
          </Link>
          {/* <Box className={classes.shareBar}>
              <Sharebar shareText={shareText} shareTitle={shareTitle}  variant="row" iconClass={shareBarIconClass}/>
          </Box> */}
    </Box>
  )
}
