import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';


export const getClasses = makeStyles(({ breakpoints }: Theme) => ({
  sharebarIcon: {
        width: "27px",
        height: "27px",
      },
  shareBar: {
      display: "flex",
      right: 0,
      alignItems: "center"
  },
  shareBarContainer: {
    paddingTop:"64px",
    display: "flex",
    width: "100%",
    alignItems: "center"
},
backLink: {
    fontSize: 14,
    fontWeight: "bold",
    textDecorationLine: 'none',
    display: "flex",
    alignItems: "center",
    marginRight: "24px"
},
}))
