import { Box, Typography } from "@material-ui/core";
import { Link } from "gatsby";
import React, { useContext } from "react";
import { pageToLink } from "../../functions/pageToLink";
import { GBlogPost } from "../../interface/graphql/GBlogPost";
import { IPageContext } from "../../interface/PageContext";
import { PageContext } from "../../layouts/MainLayout";
import { getClasses } from "./NewBlogPostListContent.jss";
import { KeyValuesContext } from "../../components/KeyValuesContext";
import { ArrowRightIcon } from "../../components/icon/ArrowRightIcon";
import { ContentLayout, ContentType } from "../../layouts/ContentLayout";
import { useViewport, BREAKPOINTS } from "@cpyou/shared/dist";
import { LayoutContext } from "../../context/layoutContext";
import ContentImage, { ContentImageType } from "../../layouts/ContentImage";

export interface IPropsBlogPostListContent {
    blogPost: GBlogPost;
    isLastBlogPost: boolean;
    isFirstBlogPost: boolean;
}

export const NewBlogPostListContent = ({
    blogPost,
    isLastBlogPost,
    isFirstBlogPost,
}: IPropsBlogPostListContent) => {
    const {
        title,
        teaserText,
        date,
        category,
        listImage,
        blogpostpage,
    } = blogPost;
    const classes = getClasses();
    const keyValues: any = useContext(KeyValuesContext);
    const { language, coBrand } = useContext(PageContext) as IPageContext;
    const readMorePage = blogpostpage !== null ? blogpostpage[0] : { slug: "" };

    const { containerRef } = useContext(LayoutContext);
    const sizeModel = useViewport(BREAKPOINTS, containerRef);

    const flexDirection =
        sizeModel.isMobile || sizeModel.isTablet ? "column" : "row";

    const containerPaddingTop =
        isFirstBlogPost && sizeModel.isMobile ? "0px" : "80px";
    const containerPaddingBottom = sizeModel.isMobile ? "50px" : (isLastBlogPost ? "80px" : "0px");
    return (
        <ContentLayout
            containerPaddingTop={containerPaddingTop}
            containerPaddingBottom={containerPaddingBottom}
            contentType={ContentType.PICTURE_TEXT_LIST}
        >
            <Box display="flex" flexDirection={flexDirection}>
                <Box
                    display="flex"
                    justifyContent="flexStart"
                    alignItems="flexStart"
                >
                    <ContentImage
                        image={listImage}
                        contentImageType={ContentImageType.BLOG_POST_IMAGE}
                    />
                </Box>
                <Box
                    display="flex"
                    flexDirection="column"
                    className={classes.blogPostListItemTextContainer}
                >
                    <Typography
                        variant="body2"
                        className={classes.blogPostListItemDate}
                    >
                        {date}
                    </Typography>
                    <Typography
                        variant="h3"
                        className={classes.blogPostListItemTitle}
                    >
                        {title}
                    </Typography>
                    <Typography
                        variant="body1"
                        className={classes.blogPostListItemTeaserText}
                    >
                        {teaserText.internal.content}
                    </Typography>
                    <Box flex="1" />
                    <Link
                        to={pageToLink(language, coBrand, readMorePage)}
                        className={classes.blogPostListItemReadMoreLink}
                    >
                        {keyValues.readMore}
                        &nbsp;
                        <ArrowRightIcon
                            color={coBrand ? coBrand.primaryColor : undefined}
                            size={16}
                        />
                    </Link>
                    <Typography
                        variant="body2"
                        className={classes.blogPostListItemCategory}
                    >
                        {keyValues[category]}
                    </Typography>
                </Box>
            </Box>
        </ContentLayout>
    );
};
