import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';


export const getClasses = makeStyles(({ breakpoints, palette}: Theme) => ({
  sharebarIcon: {
        width: 27,
        height: 27,
      },
  controllBarContainerClass: {
    [breakpoints.down('sm')]: {
      paddingTop: "0px",
      paddingBottom: "24px"
    },
    [breakpoints.only('md')]: {
      paddingTop: "0px",
      paddingBottom: "40px",
      marginTop: "-29px"
    },
    [breakpoints.only('lg')]: {
      paddingTop: "0px",
      paddingBottom: "40px",
      marginTop: "-21.5px"
    },
    [breakpoints.only('xl')]: {
      paddingTop: "141.5px",
      paddingBottom: "40px",
    },
  },
  contentHeaderImage: {
    display: "flex",
    flexDirection: "column"
  },
  contentHeaderTitle: {
    fontWeight: 'bold',
    paddingBottom: "24px",
    [breakpoints.up('md')]: {
      fontSize: "40px",
      paddingBottom: "40px"
    },
  },
  contentHeaderDate: {
    color: palette.text.secondary,
    [breakpoints.down('sm')]: {
      paddingBottom: "40px"
    },
  }
}))
