import { makeStyles } from "@material-ui/styles";
import { Theme, lighten, darken } from "@material-ui/core";
import { getColorShades } from "@cpyou/shared/dist";


export const getClasses = makeStyles(({ breakpoints, palette }: Theme) => ({
    contentBlogPostSelector: {
      backgroundColor: getColorShades(palette.primary.main, lighten, darken).tint90Percent,
      display: 'flex',
      flex:1,
      justifyContent: 'space-between',
      flexDirection: 'column',
      [breakpoints.up("md")]: {
        paddingTop: "120px",
      },
      [breakpoints.down("sm")]: {
        paddingTop: "40px",
      },
      [breakpoints.only("xs")]: {
        alignItems: 'center'
      },
    },
    carousel:{
      width: "100%"
    },
    carouselContainer: {
      width: "100%",
      display: 'flex',
      flex:1,
      [breakpoints.up("md")]: {
        justifyContent: 'space-between',
        marginBottom: "40px",
      },
      [breakpoints.down("sm")]: {
        marginBottom: "10px",
      },
      [breakpoints.only("xs")]: {
        justifyContent: 'center',
      },
    },
    viewAllBlogPostsLink: {
      [breakpoints.up("md")]: {
        justifyContent: 'space-between',
        marginBottom: "100px",
      },
      [breakpoints.down("sm")]: {
        marginBottom: "10px",
      },
    },
    carouselTileGroup: {
      width: "100%",
      marginBottom: "20px",
      display: 'flex',
      flex:1,
      [breakpoints.up("sm")]: {
        justifyContent: 'space-between',
      },
      [breakpoints.only("xs")]: {
        justifyContent: 'center',
      },
      [breakpoints.only("xl")]: {
        flexDirection: 'row'
      },
    },
  title: {
    alignSelf: "flex-start"
  },
  carouselIndicator: {
    color: '#f64b54',
  },
  subTitle: {
    lineHeight: 1.5,
    display: 'flex',
    alignSelf: "flex-start",
    [breakpoints.up("md")]: {
      marginBottom: "56px",
      paddingTop: "24px",
    },
    [breakpoints.down("sm")]: {
      marginBottom: "16px",
      marginTop: "16px",
      fontSize: 16,
    },
  },

  blogPostListItemTitle: {
    fontWeight: "bold",
    display: 'flex',
      color: palette.primary.main,
      [breakpoints.down("sm")]: {
          fontSize: 16,
      },
      [breakpoints.up("md")]: {
          fontSize: 24,
      },
  },
  blogPostListItemImageContainer: {
      paddingLeft: 0,
      paddingRight: 0,
  },

  blogPostListItemCategory: {
      color: palette.text.secondary,
      paddingTop: "16px",
  },
  blogPostListItemReadMoreLink: {
      textDecorationLine: "none",
      whiteSpace: "nowrap",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      paddingTop: "16px",
  },
  blogPostListItemTextContainer: {

      [breakpoints.down("sm")]: {
          paddingTop: "5.5px",
          paddingLeft: "14px",
          fontSize: 16,
      },
      [breakpoints.up("md")]: {
          paddingTop: "40px",
          paddingLeft: "40px",
      },
  },
  blogPostListItemTeaserText: {
    lineHeight: 1.5,
    display: 'flex',
    [breakpoints.down("sm")]: {
      paddingTop: "5.5px",
      fontSize: 16,
      display: "-webkit-box",
      "-webkit-line-clamp": 3,
      "-webkit-box-orient": "vertical",
      overflow: "hidden",
  },
    [breakpoints.up("md")]: {
        paddingTop: "24px",
        paddingBottom: "24px",
    },
    [breakpoints.only("xs")]: {
      "-webkit-line-clamp": 2,
    },

  },
  blogPostImage: {
    borderRadius: "10px",
    // objectFit: 'contain',
    width: "30%",
    minWidth: "137px"
  },
  tileContainer: {
    display:"flex",
    alignItems:"flex-start",
    [breakpoints.only("xl")]: {
      width: "50%",
      marginRight: "10px"
    },
  }
  }));
