import React, { useContext } from 'react'

import { GBlogPost } from '../../../../interface/graphql/GBlogPost'
import { getClasses } from './NewBlogPostPageContent.jss'
import { RichTextRenderer } from '../../../../components/contentful/RichtTextRenderer'
import { GLink } from '../../../../interface/graphql/GLink'
import { PageContext } from '../../../../layouts/MainLayout'
import { IPageContext } from '../../../../interface/PageContext'
import { ContentLayout } from '../../../../layouts/ContentLayout'
import { NewControlBar } from '../NewControlBar'
import { pageToLink } from "../../../../functions/pageToLink";
import { useViewport, BREAKPOINTS } from '@cpyou/shared/dist'
import { LayoutContext } from '../../../../context/layoutContext'
export interface IPropsBlogPostContent {
    blogPost: GBlogPost;
    shareText: string;
    backLink: GLink;
    ctaLink?: GLink;
}

export const NewBlogPostPageContent = ({ blogPost, shareText, backLink }: IPropsBlogPostContent) => {
    const { footnote, html, body } = blogPost;
    const classes = getClasses();
    const { language, coBrand } = useContext(PageContext) as IPageContext;
    const { containerRef } = useContext(LayoutContext);
    const sizeModel = useViewport(BREAKPOINTS, containerRef);

    const paddingBottom = sizeModel.isMobile ? "50px" : "80px";
    let newHtml = html && html[0] && html[0].longtext.longtext || ''
    /** using a workaround here to fix the broken links coming from contentful html by recreating them from slug */
    if (newHtml && typeof document!== 'undefined') {
        const el = document.createElement( 'html' );
        el.innerHTML = newHtml
        for (let i=0; i < el.getElementsByTagName('a').length; i++) {
            const slug = el.getElementsByTagName('a').item(i).attributes[0].value
            const newLink = pageToLink(language, coBrand, {slug})
            el.getElementsByTagName('a').item(i).setAttribute('href', newLink)
        }
        newHtml = el.innerHTML
    }
    return (
        <>
            <ContentLayout containerPaddingTop="0px" containerPaddingBottom={paddingBottom}>
                <div className={classes.bodyText}>
                    {newHtml && html && html.length &&
                        html.map((longtext, index) =>
                            <div key={ `html-section-${index}` } dangerouslySetInnerHTML={{ __html: newHtml }} />
                        )
                    }
                    {body && !newHtml && <RichTextRenderer>{ body }</RichTextRenderer>}
                </div>
                {footnote && <hr style={{ width: '100%' }} />}
                {footnote && <div className={ classes.footnote }><RichTextRenderer>{ footnote }</RichTextRenderer></div>}

                <NewControlBar language={language} coBrand={coBrand} backLink={ backLink } shareText={ shareText } shareTitle={ blogPost.title }/>
            </ContentLayout>
        </>
    );
}
