import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { makeStyles } from '@material-ui/styles';

export const getClasses = makeStyles(({ breakpoints }: Theme) => ({
    blogPostPageControlBar: {
        marginBottom: 70,
        marginTop: 40,
        [breakpoints.down('md')]: {
            marginBottom: 30,
        },
    },
    blogPostPageDate: {
        fontSize: 18,
        [breakpoints.down('md')]: {
            marginBottom: 30,
        },
    },
    blogPostPageTitle: {
        fontSize: 32,
        fontWeight: 'bold',
        marginBottom: 45,
        marginTop: 24,
        [breakpoints.down('md')]: {
            marginTop: 0,
            marginBottom: 10,
        },
    },
    shareBarContainer: {
        paddingBottom: "80px",
        paddingTop:"80px",
        display: "flex",
        width: "100%",
        alignItems: "center"
    },
    backLink: {
        fontSize: 14,
        fontWeight: "bold",
        textDecorationLine: 'none',
        display: "flex",
        alignItems: "center",
        marginRight: "24px"
    },
    shareBar: {
        display: "flex",
        right: 0,
        alignItems: "center"
    },
    bodyText: {
        hyphens: 'auto',
        marginTop: 56,

        [breakpoints.down('sm')]: {
            lineHeight: "24px",
            fontSize: 16,
        },
        [breakpoints.up('md')]: {
            fontSize: 24,
            lineHeight: "36px"
        },

        '& img' : {
            [breakpoints.up('md')]: {
                marginTop: "80px",
                marginBottom: "80px",
            },
            [breakpoints.down('sm')]: {
                marginTop: "40px",
                marginBottom: "40px",
            },
        },

        '& p': {
            marginBottom: 24,
        },
    },
    footnote: {
        textAlign: 'justify',
        hyphens: 'auto',
        lineHeight: '170%',
        fontSize: 14,
    },
}))
