import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { PageContext } from "../../../../layouts/MainLayout";
import { LayoutContext } from "../../../../context/layoutContext";
import { ClassNameMap } from "@material-ui/styles";
import { Box, Typography } from "@material-ui/core";
import { getClasses } from "./NewHomePageContentBlogPostsSelector.jss";
import Carousel from 'react-material-ui-carousel';
import { IPageContext } from "../../../../interface/PageContext";
import { KeyValuesContext } from "../../../../components/KeyValuesContext";
import { useViewport, BREAKPOINTS, ICoBrand, useForceUpdate, useOnResize } from "@cpyou/shared/dist";
import { GLink } from "../../../../interface/graphql/GLink";
import { GRichTextForRenderer } from "../../../../interface/graphql/GRichTextForRenderer";
import { pageToLink } from "../../../../functions/pageToLink";
import { GBlogPost } from "../../../../interface/graphql/GBlogPost";
import { Link } from "gatsby"

export interface BlogPosts {
  headline: GRichTextForRenderer;
  children: Array<GBlogPost>;
  viewAllBlogPostsLink: GLink;
}

export interface IPropNewHomePageContentBlogPosts {
  blogPosts: BlogPosts
}

export const NewHomePageContentBlogPostsSelector = ({ blogPosts }: IPropNewHomePageContentBlogPosts) => {
  const classes = getClasses();

  const { language, coBrand } = useContext(PageContext) as IPageContext

  useEffect(() => {
      blogPosts.children = blogPosts.children
        // filter out posts that are published in the future
        .filter((blogPost) => (new Date().getTime()) >= (new Date(blogPost.rawDate).getTime()) && blogPost.blogpostpage)
        // sort blogs after date
        .sort((a, b) => new Date(b.rawDate).getTime() - new Date(a.rawDate).getTime())
        // limit to 6
        .slice(0, 6);
  }, [blogPosts])

    const keyValues = useContext(KeyValuesContext);
    const { containerRef } = useContext(LayoutContext);
    const sizeModel = useViewport(BREAKPOINTS, containerRef);

    const forceUpdate = useForceUpdate();

    useLayoutEffect(() => {

      // eslint-disable-next-line
      return useOnResize(() => {
          forceUpdate();
      }, BREAKPOINTS, containerRef)
    })
    const paddingHorizontal = sizeModel.isDesktopXXL
      ? sizeModel.gutterWidth + sizeModel.marginWidth + sizeModel.columnWidth
      : sizeModel.marginWidth

    return (
      <Box className={classes.contentBlogPostSelector} paddingX={`${paddingHorizontal}px`}>
        <Typography variant='h2' className={classes.title}>
          {keyValues.homePageBlogTitle}
        </Typography>
        <Typography variant='body1' className={classes.subTitle}>
          {keyValues.homePageBlogSubTitle}
        </Typography>

        <Box className={classes.carouselContainer} >
          {sizeModel.isTablet && <TabletCarousel language={language} coBrand={coBrand} tiles={blogPosts} classes={classes}/>}
          {sizeModel.isMobile && <MobileCarousel language={language} coBrand={coBrand} tiles={blogPosts} classes={classes}/>}
          {sizeModel.isDesktopXXL && <LargeDesktopCarousel language={language} coBrand={coBrand} tiles={blogPosts} classes={classes}/>}
          {!sizeModel.isTablet && !sizeModel.isMobile && !sizeModel.isDesktopXXL &&
            <DesktopCarousel language={language} coBrand={coBrand} tiles={blogPosts} classes={classes}/>
          }
        </Box>
        <Link
          className={classes.viewAllBlogPostsLink}
          to={(pageToLink(
            language,
            coBrand,
            {slug: blogPosts.viewAllBlogPostsLink.page.slug}))}>
             <Typography variant='body1'>{blogPosts.viewAllBlogPostsLink.title}</Typography>
        </Link>
      </Box>
    );
};

const Tile = ({tile, classes, language, coBrand} : {tile: GBlogPost, classes: ClassNameMap, language: string, coBrand: ICoBrand}) => {
  const keyValues: any = useContext(KeyValuesContext);
  const slug = tile.blogpostpage && tile.blogpostpage[0] && tile.blogpostpage[0].slug || '';
  const teaser = tile.shortTeaserForHomepage || tile.teaserText.internal.content;
  const title = tile.shortTitleForHomepage || tile.title;
  return (
    <Box className={classes.tileContainer} >
      <img src={tile.listImage.file.url}  className={classes.blogPostImage}/>
      <Box
          display="flex"
          flex={1}
          flexDirection="column"
          className={classes.blogPostListItemTextContainer}
      >
        <Typography
            variant="h4"
            className={classes.blogPostListItemTitle}
        >
            {title}
        </Typography>
        <Typography
            variant="body1"
            className={classes.blogPostListItemTeaserText}
        >
            {teaser}
        </Typography>
        { slug && (
            <Link to={(pageToLink(language, coBrand, {slug}))}>
                <Typography variant='body1'>{keyValues.readMore}</Typography>
            </Link>
        )}
      </Box>
    </Box>
   )
}

const TabletCarousel = ({tiles, classes, language, coBrand}: {tiles: BlogPosts, classes: ClassNameMap, language: string, coBrand: ICoBrand}) => (
  <Carousel
    autoPlay={false}
    animation="slide"
    navButtonsAlwaysInvisible
    className={classes.carousel}
    indicators={tiles.children.length > 2 ? true : false}
    activeIndicatorIconButtonProps={{ className: classes.carouselIndicator, style: {}}}
  >
   {tiles.children.map(tile => <Tile language={language} coBrand={coBrand} tile={tile} classes={classes} key={tile.title} ></Tile>)}
  </Carousel>
)

const MobileCarousel = ({tiles, classes, language, coBrand}: {tiles: BlogPosts, classes: ClassNameMap, language: string, coBrand: ICoBrand}) => (
  <Carousel
    autoPlay={false}
    animation="slide"
    navButtonsAlwaysInvisible
    className={classes.carousel}
    indicators={tiles.children.length > 1 ? true : false}
    activeIndicatorIconButtonProps={{ className: classes.carouselIndicator, style: {}}}
  >
      {tiles.children.map(tile => <Tile language={language} coBrand={coBrand} key={tile.title} tile={tile} classes={classes}></Tile>)}
  </Carousel>
)

const DesktopCarousel = ({tiles, classes, language, coBrand}: {tiles: BlogPosts, classes: ClassNameMap, language: string, coBrand: ICoBrand}) => (
  <Carousel
    autoPlay={false}
    animation="slide"
    navButtonsAlwaysInvisible
    className={classes.carousel}
    indicators={tiles.children.length > 2 ? true : false}
    activeIndicatorIconButtonProps={{ className: classes.carouselIndicator, style: {}}}
  >
      {tiles.children.map(tile => <Tile language={language} coBrand={coBrand}  key={tile.title} tile={tile} classes={classes}></Tile>)}
  </Carousel>
)

const LargeDesktopCarousel = ({tiles, classes, language, coBrand}: {tiles: BlogPosts, classes: ClassNameMap, language: string, coBrand: ICoBrand}) => (
  <Carousel
    autoPlay={false}
    animation="slide"
    navButtonsAlwaysInvisible
    className={classes.carousel}
    indicators={tiles.children.length > 2 ? true : false}
    activeIndicatorIconButtonProps={{ className: classes.carouselIndicator, style: {}}}
  >
      {tiles.children.filter((tile, idx) => idx % 2 === 0).map((tile, idx) => {
        const tile1 = tile
        const tile2 = tiles.children[2 * idx  + 1]

        return (
          <Box display='flex' key={`${tile.id}`}>

            {/* showing two blogposts per slide */}
            {tile && <Tile language={language} coBrand={coBrand}  key={tile.title} tile={tile} classes={classes}></Tile>}
            {tiles.children[2 * idx + 1] &&
              <Tile
                language={language}
                coBrand={coBrand}
                key={tiles.children[2 * idx + 1].title}
                tile={tiles.children[2* idx +1]} classes={classes}/>
            }
          </Box>
        )
      }
      )}
  </Carousel>
)
